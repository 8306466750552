const cache: Record<string, string> = {};

const stars = [
  '*',
  '**',
  '***',
  '****',
  '*****',
  '******',
  '*******',
  '********',
  '*********',
  '**********',
  '***********',
  '************',
  '*************',
  '**************',
  '***************',
  '****************',
  '*****************',
];

function starN(n: number): string {
  n = Math.floor(n);
  if (n <= 0) return '';
  const v = stars[n - 1];
  if (v) return v;
  return '*'.repeat(n);
}

function doMask(id: string): string {
  const len = id.length;
  if (len === 1) return '*';
  if (len === 2) return `${id.substring(0, 1)}*`;
  if (len === 3) return `${id.substring(0, 2)}*`;
  if (len === 4) return `${id.substring(0, 2)}**`;
  if (len === 5) return `${id.substring(0, 3)}**`;
  if (len === 6) return `${id.substring(0, 4)}**`;

  // 7 AA*****AA  => AA***AA
  // 8 AA******AA => AA****AA

  // 별표를 중간에 붙이는 방식
  // const starLen = Math.floor(len / 2)
  // const suffixLen = Math.floor((len - starLen) / 2)
  // const prefixLen = len - suffixLen - starLen
  // return `${id.substring(0, prefixLen)}${starN(starLen)}${id.substring(len - suffixLen)}`

  // 별표를 끝에 붙이는 방식
  const starLen = Math.floor(len * 0.4);
  const prefixLen = len - starLen;
  return `${id.substring(0, prefixLen)}${starN(starLen)}`;

  // if (len < 3) {
  //     return id.replace(/(?<=.{1})./gi, '*')
  // }
  // return id.replace(/(?<=.{2})./gi, '*')
}

function maskUserIdInternal(id: string): string {
  let maskedId = cache[id];
  if (maskedId) return maskedId;
  maskedId = doMask(id);
  cache[id] = maskedId;
  return maskedId;
}

export function maskUserId(id?: string | null, disabled = false): string {
  if (disabled) return id ?? '';
  if (!id) return '';
  if (id === '관리자') return id;
  return maskUserIdInternal(id);
}
