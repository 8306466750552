import type { Breakpoint } from '@mui/material';

export const mainLayoutConstants = {
  appbarHeight: 60,

  sidemenu: {
    /**
     * 왼쪽 메뉴 너비
     */
    width: 270,

    /**
     * 왼쪽 메뉴 배경색
     */
    bgColor: '#2C2E35',
    bgColorBack: 'rgba(0,0,0,0.2)',
    bgColorHoverBack: 'rgba(0,0,0,0.1)',
    fgColor: '#eee',
    fgColorActive: '#FDCC90',
    iconColor: '#fff',
    iconColorActive: '#FCAA44',
    iconWidth: 32,

    /**
     * 왼쪽 구분선 색
     */
    dividerColor: 'rgba(255,255,255,0.2)',

    /**
     * 왼쪽메뉴 글자 크기
     */
    fontSize: '0.85rem',
  },

  footerHeight: 163 + 32,
  contentBgColor: '#fff',
  contentPaddingBottom: 80,
  // 사이드메뉴에서 모바일로 인식할 breakpoint
  sidemenuMobileBreakpoint: 'sm' as Breakpoint,
};
