import { LRUCache } from 'lru-cache';

export type MenuIdType = string | number;

export type MatchFn = (path: string) => boolean;

export interface IDivider {
  id: MenuIdType;
  type: 'divider';
  sectionId?: MenuIdType;
}

export interface ILabel {
  id: MenuIdType;
  type: 'label';
  icon?: React.ReactNode | string;
  title: string;
  sectionId?: MenuIdType;
}

export interface IMenu {
  id: MenuIdType;
  type: 'menu';
  title: string;
  href: string;
  icon?: React.ReactNode;
  sectionId?: MenuIdType;
  match?: MatchFn;
}

/**
 * Section 객체
 * Section 객체는 id와 sectionId가 동일하다
 */
export interface ISection {
  id: MenuIdType;
  type: 'section';
  icon?: React.ReactNode;
  title: string;
  sectionId: MenuIdType;
  submenus: (IDivider | IMenu)[];
}

export type ISideMenuItem = ILabel | IDivider | IMenu | ISection;

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type ISideMenuItemWithoutId =
  | Optional<ILabel, 'id' | 'sectionId'>
  | Optional<IDivider, 'id' | 'sectionId'>
  | Optional<IMenu, 'id' | 'sectionId'>
  | (Optional<Omit<ISection, 'submenus'>, 'id' | 'sectionId'> & {
      submenus: (Optional<IDivider, 'id' | 'sectionId'> | Optional<IMenu, 'id' | 'sectionId'>)[];
    });

function assignId(generateMenuId: () => MenuIdType, item: ISideMenuItemWithoutId): ISideMenuItem {
  if ('id' in item) {
    return item as ISideMenuItem;
  }

  const id = generateMenuId();
  if (item.type === 'divider') {
    return { ...item, id } as IDivider;
  }

  if (item.type === 'label') {
    return { ...item, id } as ILabel;
  }

  if (item.type === 'menu') {
    return { ...item, id } as IMenu;
  }

  if (item.type === 'section') {
    const sectionId = id;
    const section = { ...item, id, sectionId } as ISection;
    if (!section.submenus || section.submenus.length === 0) return section;
    section.submenus = section.submenus.map((it) => {
      if (it.type === 'divider') {
        return { ...it, id: generateMenuId(), sectionId } as IDivider;
      }
      if (it.type === 'menu') {
        return { ...it, id: generateMenuId(), sectionId } as IMenu;
      }
      throw new Error('unknown side submenu type');
    });
    return section;
  }

  throw new Error('unknown side menu item type');
}

type MenuIdGeneratorFn = () => MenuIdType;

let seq = 0;
const defaultIdGenerator = () => `autoid_${++seq}`;

export function configureSideMenus(
  menuItems: ISideMenuItemWithoutId[],
  generateId: MenuIdGeneratorFn = defaultIdGenerator,
): MenuManager {
  return new MenuManager(menuItems.map((it) => assignId(generateId, it)));
}

export class MenuManager {
  private menuMatchedCache_ = new LRUCache<string, IMenu>({
    max: 500,
  });

  private sectionMatchedCache_ = new LRUCache<string, ISection>({
    max: 500,
  });

  private sectionsById_: Record<MenuIdType, ISection> = {};
  private menusById_: Record<MenuIdType, IMenu> = {};

  constructor(public readonly menuItems: ISideMenuItem[]) {
    menuItems.forEach((item) => {
      if (item.type === 'section') {
        this.sectionsById_[item.id] = item;
      }
      if (item.type === 'menu') {
        this.menusById_[item.id] = item;
      }
    });
  }

  isMenuPathMatched = (menu: IMenu, currentPath: string | undefined | null): boolean => {
    if (!currentPath) return false;
    const currPath = removeLangPrefix(currentPath);

    // check cache
    const cachedMenuItem = this.menuMatchedCache_.get(currPath);
    if (cachedMenuItem) {
      // console.log('menu cache hit: ', { currentPath, title: menu.title })
      return cachedMenuItem.id === menu.id;
    }

    const matched = isMenuPathMatched_(menu, currPath);
    // console.log("isMenuPathMatched_", matched, currPath, menu.href);
    if (matched) {
      // save to cache
      // console.log('menu cache saved', { currentPath, title: menu.title })
      this.menuMatchedCache_.set(currPath, menu);
      const sectionId = menu.sectionId;
      if (sectionId) {
        const section = this.sectionsById_[sectionId];
        if (!section) {
          throw new Error(`unexcepted state: section is not exists: sectionId=${sectionId}`);
        }
        this.sectionMatchedCache_.set(currPath, section);
      }
    }
    return matched;
  };

  isSectionPathMatched = (section: ISection, currentPath: string | undefined | null): boolean => {
    if (!currentPath) return false;
    const currPath = removeLangPrefix(currentPath);

    // check cache
    const cachedItem = this.sectionMatchedCache_.get(currPath);
    if (cachedItem) {
      // console.log('section cache hit: ', { currentPath, title: section.title })
      return cachedItem.id === section.id;
    }

    const matched = section.submenus.some(
      (it) => it.type === 'menu' && this.isMenuPathMatched(it, currPath),
    );
    if (matched) {
      // save to cache
      // console.log('section cache saved', { currPath, title: section.title })
      this.sectionMatchedCache_.set(currPath, section);
    }
    return matched;
  };
}

const isMenuPathMatched_ = (menuItem: IMenu, currentPath: string | undefined | null): boolean => {
  if (!currentPath) return false;
  const menuPath = menuItem.href;

  // 가장 빠르게 비교
  if (menuPath === currentPath) return true;

  // 매치 함수가 있다면 매치 함수로 체크
  if (menuItem.match) {
    return menuItem.match(currentPath);
  }

  // menuPath에 '?'가 없는 경우 매치
  if (!menuPath.includes('?')) {
    if (currentPath.indexOf('?') > 0) {
      return currentPath.includes(`${menuPath}?`);
    }
    return false;
  }

  // menuPath에 '?'가 있다면
  // currentPath에 menuPath가 포함하고 있어야 한다.
  return currentPath.startsWith(menuPath);
};

/**
 * 주어진 경로의 lang prefix를 제거한다.
 * 쿼리 문자열도 제거한다.
 *
 * @param str - 대상 문자열
 * @returns lang prefix가 제거된 문자열
 *
 * @example
 * removeLangPrefix('/') => '/'
 * removeLangPrefix('') => '/'
 * removeLangPrefix('/ko/sim') => '/sim'
 * removeLangPrefix('/ko/sim/') => '/sim'
 * removeLangPrefix('/ko/sim/hello') => '/sim/hello'
 */
function removeLangPrefix(str: string): string {
  if (str.length === 0) return '/';

  let s = str;
  const idx = s.indexOf('?');
  if (idx >= 0) {
    s = s.substring(0, idx);
  }

  const arr = s.split('/').filter((it) => it.length > 0);
  if (arr.length <= 0) {
    return str;
  }
  return '/' + arr.slice(1).join('/');
}
