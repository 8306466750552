import type * as nav from '../nav/index';
import { create } from 'zustand';

export interface MainLayoutState {
  /**
   * 왼쪽 메뉴 열림 상태
   */
  isSidebarOpened: boolean;

  isSidebarAutoOpenChecked: boolean;

  /**
   * 펼쳐진 섹션 메뉴 ID
   */
  expandedSectionIds: nav.MenuIdType[];

  /**
   * 왼쪽 메뉴 열림 상태 설정
   */
  setSidebarOpened: (isOpened: boolean) => void;
  setSidebarAutoOpenChecked: (checked: boolean) => void;

  /**
   * 왼쪽 메뉴 열림 상태 토글
   */
  toggleSidebarOpen: () => void;

  /**
   * 섹션 펼침 토글
   */
  toggleExpandSection: (sectionId: nav.MenuIdType) => void;

  /**
   * 섹션 펼치기
   */
  expandSection: (sectionId: nav.MenuIdType) => void;

  /**
   * 섹션 접기
   */
  foldSection: (sectionId: nav.MenuIdType) => void;
}

export const useMainLayoutStore = create<MainLayoutState>()((set) => ({
  isSidebarOpened: false,
  expandedSectionIds: [],
  isSidebarAutoOpenChecked: false,
  setSidebarOpened: (isOpened: boolean) => {
    set((state) => ({
      isSidebarOpened: isOpened,
    }));
  },
  setSidebarAutoOpenChecked: (isChecked: boolean) => {
    set((state) => ({
      isSidebarAutoOpenChecked: isChecked,
    }));
  },

  toggleSidebarOpen: () => {
    set((state) => ({
      isSidebarOpened: !state.isSidebarOpened,
    }));
  },

  toggleExpandSection: (sectionId: nav.MenuIdType) => {
    set((state) => {
      const sectionIds = state.expandedSectionIds;
      const idx = sectionIds.indexOf(sectionId);
      if (idx >= 0) {
        return { expandedSectionIds: sectionIds.filter((it) => it !== sectionId) };
      }
      return { expandedSectionIds: sectionIds.concat(sectionId) };
    });
  },
  foldSection: (sectionId: nav.MenuIdType) => {
    set((state) => {
      const sectionIds = state.expandedSectionIds;
      const idx = sectionIds.indexOf(sectionId);
      if (idx >= 0) {
        return { expandedSectionIds: sectionIds.filter((it) => it !== sectionId) };
      }
      return state;
    });
  },
  expandSection: (sectionId: nav.MenuIdType) => {
    set((state) => {
      const sectionIds = state.expandedSectionIds;
      const idx = sectionIds.indexOf(sectionId);
      if (idx < 0) {
        return { expandedSectionIds: sectionIds.concat(sectionId) };
      }
      return state;
    });
  },
}));
